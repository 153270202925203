import PropTypes from "prop-types";

function IconShoppingBag({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M51.93,30H49.11a2.05,2.05,0,0,0-.3-1.06l-10-16a2,2,0,1,0-3.39,2.12L44.75,30H17.47l9.34-14.94a2,2,0,1,0-3.39-2.12l-10,16a2,2,0,0,0-.3,1.06H10.3a2.29,2.29,0,0,0-2.18,3l6.47,19.41A2.29,2.29,0,0,0,16.77,54H45.46a2.3,2.3,0,0,0,2.18-1.57L54.11,33A2.3,2.3,0,0,0,51.93,30Z" />
        </svg>
    );
}

IconShoppingBag.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconShoppingBag.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconShoppingBag;
