import PropTypes from "prop-types";
import { useState } from "react";
import Link from "next/link";
import Image from "next/image";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import IconShoppingBag from "components/ui/icons/IconShoppingBag";

import Navigation from "./navigation/Navigation";
import Hamburger from "./hamburger/Hamburger";
import Menu from "./menu/Menu";

import { Container, Wrapper, LogoLink, OrderButton } from "./Header.styled";

function Header({ forceMobileView, showOrderButton, showMenu }) {
    const [open, setOpen] = useState(false);
    const { desktopView } = useMediaQueryContext();

    return (
        <Container>
            <Wrapper>
                {(!desktopView || forceMobileView) && showMenu && (
                    <>
                        <Hamburger setOpen={setOpen} open={open} />
                        <Menu open={open} setOpen={setOpen} />
                    </>
                )}

                <Link href="/" passHref prefetch={false}>
                    <LogoLink
                        forceMobileView={forceMobileView}
                        showOrderButton={showOrderButton}
                    >
                        <Image
                            src="/images/logos/logo-ndc-color.svg"
                            alt="Nationale Diner Cadeaukaart logo"
                            unoptimized
                            layout="fill"
                            priority
                        />
                    </LogoLink>
                </Link>

                {desktopView && !forceMobileView && <Navigation />}

                {showOrderButton && (
                    <Link
                        href="/restaurantbon-bestellen/"
                        passHref
                        prefetch={false}
                    >
                        <OrderButton element="a" variant="primary">
                            bestellen
                            {desktopView && (
                                <IconShoppingBag
                                    fill="var(--color-white)"
                                    size={22}
                                />
                            )}
                        </OrderButton>
                    </Link>
                )}
            </Wrapper>
        </Container>
    );
}

Header.propTypes = {
    forceMobileView: PropTypes.bool,
    showOrderButton: PropTypes.bool,
    showMenu: PropTypes.bool
};

Header.defaultProps = {
    forceMobileView: false,
    showOrderButton: true,
    showMenu: true
};

export default Header;
