import styled, { css } from "styled-components";

import media, { sizes } from "constants/media";

import Button from "components/ui/button/Button";

export const Container = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;

    display: flex;
    justify-content: flex-end;
    width: calc(100vw - 10px);
    height: 60px;

    background-color: var(--color-white);
    border-radius: 0 0 10px;
    box-shadow: -20px 3px 44px 0 rgba(0, 0, 0, 0.15);

    ${media.mdUp} {
        justify-content: right;
        width: calc(100vw - 20px);
        height: 100px;
        margin-right: 20px;
    }

    ${media.lgUp} {
        width: calc(50vw + ${sizes.lg}px / 2);
    }

    ${media.xlUp} {
        width: calc(50vw + ${sizes.xl}px / 2);
    }
`;

export const LogoLink = styled.a`
    position: absolute;
    left: 50%;

    width: 77px;
    height: 42px;

    transform: translateX(calc(-50% + 5px));

    cursor: pointer;

    ${media.mdUp} {
        width: 130px;
        height: 70px;
    }

    ${media.xlUp} {
        width: 160px;
    }

    ${media.lgUp} {
        ${({ showOrderButton }) =>
            showOrderButton
                ? css`
                      position: relative;
                      left: auto;

                      transform: ${({ forceMobileView }) =>
                          forceMobileView ? "translate(45px, 0)" : "none"};
                  `
                : css`
                      position: absolute;
                      left: 50%;

                      transform: translate(-50%);
                  `}
    }
`;

export const Wrapper = styled.div`
    position: relative;
    z-index: 30;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;

    font-size: 13px;
    line-height: 18px;

    ${media.lgUp} {
        max-width: ${sizes.lg - 10}px;
        padding: 0 20px 0 0;
    }

    ${media.xlUp} {
        max-width: ${sizes.xl - 10}px;
        padding: 0 20px 0 0;
    }
`;

export const OrderButton = styled(Button)`
    position: relative;
    z-index: 0;

    flex: 0 0 auto;

    ${media.mdDown} {
        padding: 10px 0;

        color: var(--color-purple);
        font-size: 13px;

        background-color: var(--color-white);
        box-shadow: none;

        svg {
            display: none;
        }

        :hover,
        :focus {
            color: var(--color-purple);

            background-color: var(--color-white);
        }
    }
`;
