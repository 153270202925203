import styled from "styled-components";
import media, { sizes } from "constants/media";

export const ContainerStyled = styled.div`
    position: relative;

    width: 100%;
    margin: 0 auto;
    padding: 0 10px;

    ${media.mdUp} {
        max-width: ${sizes.md}px;
    }

    ${media.lgUp} {
        max-width: ${sizes.lg}px;
    }

    ${media.xlUp} {
        max-width: ${({ narrow }) => (narrow ? 980 : sizes.xl)}px;
    }
`;
