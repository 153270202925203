import PropTypes from "prop-types";

function IconChevronRight({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M40.33 35.73 25.48 50.58 21.24 46.33 36.09 31.48 21.24 16.63 25.48 12.39 44.58 31.48 40.33 35.73z" />
        </svg>
    );
}

IconChevronRight.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconChevronRight.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconChevronRight;
