import PropTypes from "prop-types";
import Link from "next/link";
import { useTrail, a, config, useTransition } from "@react-spring/web";

import { Navigation, Overlay, List, Anchor } from "./Menu.styled";

const items = [
    {
        url: "/",
        label: "Home"
    },
    {
        url: "/restaurantbon-bestellen/",
        label: "Dinerbon bestellen"
    },
    {
        url: "/saldocheck/",
        label: "Saldocheck"
    },
    {
        url: "/restaurants/",
        label: "Restaurants"
    },
    {
        url: "/acceptant/",
        label: "Acceptant worden"
    },
    {
        url: "/zakelijk/",
        label: "Zakelijk"
    },
    {
        url: "/helpdesk/",
        label: "Helpdesk"
    },
    {
        url: "/verdubbelaar/",
        label: "Verdubbelaar"
    }
];

function Menu({ open, setOpen }) {
    const trail = useTrail(items.length, {
        config: config.stiff,
        x: open ? 0 : -5,
        opacity: open ? 1 : 0,
        from: { opacity: 0, x: -5 }
    });
    const transitions = useTransition(open, {
        from: {
            width: "0vw",
            height: "0vh",
            opacity: 0
        },
        enter: {
            width: "200vw",
            height: "200vh",
            opacity: 0.5
        },
        leave: {
            width: "0vw",
            height: "0vh",
            opacity: 0
        },
        reverse: open
    });

    return (
        <>
            <Navigation open={open} role="navigation" aria-label="Main">
                <List>
                    {trail.map(({ ...style }, index) => (
                        <a.li
                            key={JSON.stringify(items[index].label)}
                            style={style}
                        >
                            <Link
                                href={items[index].url}
                                passHref
                                prefetch={false}
                            >
                                <Anchor>{items[index].label}</Anchor>
                            </Link>
                        </a.li>
                    ))}
                </List>
            </Navigation>
            {transitions(
                (styles, item) =>
                    item && (
                        <Overlay
                            style={styles}
                            open={open}
                            onClick={() => {
                                setOpen(!open);
                            }}
                        />
                    )
            )}
        </>
    );
}

Menu.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func
};

Menu.defaultProps = {
    open: false,
    setOpen: () => {}
};

export default Menu;
