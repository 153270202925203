import styled, { css } from "styled-components";
import { a } from "@react-spring/web";

import media from "constants/media";

export const Navigation = styled.nav`
    position: fixed;
    top: 0;
    left: -280px;
    z-index: 32;

    display: flex;
    width: 280px;
    padding: 80px 20px 50px;

    background-color: var(--color-white);
    border-radius: 0 0 35px;

    transition: left 0.25s, opacity 0.25s;
    transition-timing-function: ease-in-out;

    ${({ open }) =>
        open &&
        css`
            left: 0;

            opacity: 1;
        `}

    ${media.lgUp} {
        left: calc(((50vw - var(--container-width) / 2) + 310px) * -1);

        justify-content: right;
        width: calc((50vw - var(--container-width) / 2) + 310px);

        ${({ open }) =>
            open &&
            css`
                left: 0;
            `}
    }
`;

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 280px;
    margin: 0;
    padding: 0;

    text-align: left;

    list-style: none;
`;

export const Anchor = styled.a`
    display: block;

    color: var(--color-purple);
    font-weight: bold;
    font-size: var(--font-size-body);
    line-height: 50px;

    transition: all var(--animation-duration-default) ease;

    :hover,
    :focus {
        color: var(--color-yellow);
    }
`;

export const Overlay = styled(a.div)`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 31;

    width: 0;
    height: 0;

    background: var(--color-gray-30);
    border-radius: 100%;
    transform: translate3d(-50vw, -50vh, 0);
    opacity: 0;
    filter: blur(100px);
`;
