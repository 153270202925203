import styled, { css } from "styled-components";

export const Line = styled.span`
    position: absolute;

    display: block;
    width: 100%;
    height: 2px;

    background-color: var(--color-black);
    transform: rotate(0deg);

    transition: all var(--animation-duration-default) ease;
`;

export const Icon = styled.div`
    position: relative;

    display: block;
    width: 19px;
    height: 14px;

    ${Line} {
        ${({ open }) =>
            open === false &&
            css`
                :nth-child(1) {
                    top: 0;
                }

                :nth-child(2) {
                    top: 6px;

                    width: 16px;
                }

                :nth-child(3) {
                    top: 12px;

                    width: 12px;
                }
            `}

        ${({ open }) =>
            open === true &&
            css`
                :nth-child(1) {
                    top: 6px;

                    transform: rotate(45deg);
                }
                :nth-child(2) {
                    top: 6px;
                    width: 19px;

                    transform: rotate(-45deg);
                }
                :nth-child(3) {
                    top: 6px;

                    opacity: 0;
                }
            `}
    }
`;

export const Text = styled.span`
    display: block;
    margin-left: 10px;

    font-weight: bold;
    font-size: 13px;
`;

export const MenuButton = styled.button`
    position: relative;
    z-index: 33;

    display: flex;
    flex-direction: row;
    padding: 0;

    background: transparent;
    border: 0;
    cursor: pointer;

    :hover {
        ${Line} {
            background-color: var(--color-purple-30);
        }

        ${Text} {
            color: var(--color-purple-30);
        }
    }
`;
