import PropTypes from "prop-types";
import NextHead from "next/head";
import { useRouter } from "next/router";

function parseCanonical(canonical, path = "") {
    if (canonical && typeof canonical === "string" && canonical !== "") {
        return canonical;
    }

    const url = new URL(`${process.env.NEXT_PUBLIC_BASE_URL}${path}`);
    return `${process.env.NEXT_PUBLIC_BASE_URL}${url.pathname}`;
}

function Head({ siteName, title, description, keywords, canonical }) {
    const router = useRouter();
    const canonicalUrl = parseCanonical(canonical, router.asPath);

    return (
        <NextHead>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <link rel="canonical" href={canonicalUrl} />
            <meta name="application-name" content={siteName} />
            <meta name="apple-mobile-web-app-title" content={siteName} />
            <meta name="description" content={description} />
            <meta property="og:site_name" content={siteName} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
        </NextHead>
    );
}

Head.propTypes = {
    siteName: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    canonical: PropTypes.string
};

Head.defaultProps = {
    siteName: "Nationale Diner Cadeaukaart",
    title: "Nationale Diner Cadeaukaart | De restaurantbon van Nederland!",
    description:
        "De Nationale Diner Cadeaukaart is de leukste restaurantbon van Nederland. Keuze uit vele toprestaurants!",
    keywords: ["cadeaukaart", "diner", "restaurant", "uiteten", "nationaal"],
    canonical: undefined
};

export default Head;
