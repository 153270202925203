import PropTypes from "prop-types";

import { ContainerStyled } from "./Container.styled";

export default function Container({ className, children, narrow, ...rest }) {
    return (
        <ContainerStyled className={className} narrow={narrow} {...rest}>
            {children}
        </ContainerStyled>
    );
}

Container.propTypes = {
    children: PropTypes.node.isRequired,
    narrow: PropTypes.bool,
    className: PropTypes.string
};

Container.defaultProps = {
    narrow: false,
    className: ""
};
