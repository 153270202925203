import PropTypes from "prop-types";

import { Icon, Line, Text, MenuButton } from "./Hamburger.styled";

function Hamburger({ open, setOpen }) {
    return (
        <MenuButton onClick={() => setOpen(!open)}>
            <Icon open={open}>
                <Line />
                <Line />
                <Line />
            </Icon>
            <Text>{open ? "sluiten" : "menu"}</Text>
        </MenuButton>
    );
}

Hamburger.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func.isRequired
};

Hamburger.defaultProps = {
    open: false
};

export default Hamburger;
