import Link from "next/link";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import { Container, List, Item, Anchor } from "./Navigation.styled";

const items = [
    {
        url: "/restaurantbon-bestellen/",
        label: "Bestellen"
    },
    {
        url: "/saldocheck/",
        label: "Saldocheck"
    },
    {
        url: "/restaurants/",
        label: "Restaurants"
    },
    {
        url: "/acceptant/",
        label: "Acceptant worden"
    },
    {
        url: "/zakelijk/",
        label: "Zakelijk"
    },
    {
        url: "/helpdesk/",
        label: "Helpdesk"
    },
    {
        url: "/verdubbelaar/",
        label: "Verdubbelaar"
    }
];

function Navigation() {
    const { containerWidth } = useMediaQueryContext();

    return (
        <Container
            containerWidth={containerWidth}
            role="navigation"
            aria-label="Main"
        >
            <List>
                {items.map((item, index) => (
                    <Item key={JSON.stringify(item.label)}>
                        <Link href={item.url} passHref prefetch={false}>
                            <Anchor last={index === items.length - 1}>
                                {item.label}
                            </Anchor>
                        </Link>
                    </Item>
                ))}
            </List>
        </Container>
    );
}

export default Navigation;
