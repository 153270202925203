import styled, { css } from "styled-components";

export const Container = styled.nav`
    width: 100%;
`;

export const List = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;

    text-align: left;

    list-style: none;
`;

export const Item = styled.li`
    ${({ first }) =>
        first &&
        css`
            display: none;
        `}
`;

export const Anchor = styled.a`
    padding-bottom: 10px;

    color: var(--color-${({ last }) => (last ? "purple" : "black")});
    font-weight: ${({ last }) => (last ? "bold" : "normal")};
    font-size: 14px;
    line-height: 70px;

    border-bottom: 2px solid rgba(102, 36, 131, 0);

    transition: all var(--animation-duration-default) ease;

    :hover,
    :active {
        color: var(--color-purple);

        border-color: rgba(102, 36, 131, 1);
    }
`;
